<template>
    <div class="relative">
        <div
            class="relative px-4 py-5 border-b border-gray-200 shadow-inner sm:px-6"
        >
          <div class="absolute right-0 top-0 pr-4 pt-4 z-50">
            <a :href="providerDashboardLink"
               target="_blank"
               class="flex items-center hover:underline shadow-inner"
              :class="{ 'bg-gray-300 rounded-md py-1 px-1.5' : status === StepsStatusEnum.CANCELLED }"
            >
              <span v-if="status === StepsStatusEnum.CANCELLED" class="text-xs font-medium text-gray-900 mr-2">View in</span>
              <img
                  v-if="providerImage"
                  :src="providerImage"
                  class="h-6 rounded-md shadow-inner"
                  alt="View in provider dashboard"
              />

            </a>
          </div>
            <div>
                <span class="text-gray-600 text-base"></span>
                <div
                    v-if="delivery"
                    class="absolute left-0 top-0 pl-4 pt-4 text-gray-500 text-sm"
                >
                    <span class="text-xs flex items-center"
                        >{{ delivery.courierName }}
                        <a v-if="delivery.courierPhone" :href="`tel:${delivery.courierPhone}`" class="hover:underline flex items-center font-medium" ><base-phone-icon class="ml-2 mr-0.5 w-3 h-3" /> {{ delivery.courierPhone }}</a>
                    </span>
                </div>
                <div class="steps relative mt-4 pt-5">
                    <ul
                        class="steps-container flex justify-between w-full list-none"
                    >
                        <li v-for="(step, key) in steps" :key="key">
                            <div class="step">
                                <div class="step-image">
                                    <span
                                        :class="{
                                            animate: status === StepsStatusEnum[key]
                                        }"
                                    >
                                    </span>
                                </div>
                                <div class="step-text text-gray-600 text-xs">
                                    {{ step }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
          <div v-if="status === StepsStatusEnum.CANCELLED" class="absolute bg-gray-200 opacity-80 blur-lg top-0 left-0 w-full h-full flex items-center justify-center">
            <base-warning-icon
                class="w-4 h-4 mr-1 text-red-700 inline-block"
            />
            <span class="text-sm font-medium leading-5 text-gray-900">Delivery cancelled</span>
          </div>
        </div>
    </div>
</template>

<script>
import { DeliveryStatusEnum, DeliveryProvidersEnum } from '@/enums';

const StepsStatusEnum = {
    PENDING: 'pending',
    DRIVER_ASSIGNED: 'driver_assigned',
    DRIVER_EN_ROUTE: 'driver_en_route',
    DRIVER_AT_DROPOFF: 'driver_at_dropoff',
    DELIVERED: 'delivered',
    CANCELLED: 'cancelled'
}

const steps = {
    PENDING: 'Pending',
    DRIVER_ASSIGNED: 'Driver assigned',
    DRIVER_EN_ROUTE: 'Driver en route',
    DRIVER_AT_DROPOFF: 'Driver at dropoff',
    DELIVERED: 'Delivered'
}

export default {
    name: 'DeliveryManagement',
    props: {
        delivery: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            StepsStatusEnum,
            steps
        };
    },
    computed: {
        status() {
            switch (this.delivery.status) {
                case DeliveryStatusEnum.CREATED:
                case DeliveryStatusEnum.SCHEDULED:
                case DeliveryStatusEnum.NOT_ASSIGNED_DRIVER:
                    return StepsStatusEnum.PENDING;
                case DeliveryStatusEnum.ASSIGNED_DRIVER:
                case DeliveryStatusEnum.STARTED:
                    return StepsStatusEnum.DRIVER_ASSIGNED
                case DeliveryStatusEnum.PICKUP_ENROUTE:
                case DeliveryStatusEnum.PICKUP_ARRIVED:
                case DeliveryStatusEnum.PICKUP_COMPLETE:
                case DeliveryStatusEnum.DROPOFF_ENROUTE:
                    return StepsStatusEnum.DRIVER_EN_ROUTE
                case DeliveryStatusEnum.DROPOFF_ARRIVED:
                    return StepsStatusEnum.DRIVER_AT_DROPOFF
                case DeliveryStatusEnum.DROPOFF_COMPLETE:
                case DeliveryStatusEnum.RETURN_IN_PROGRESS:
                case DeliveryStatusEnum.RETURNED:
                    return StepsStatusEnum.DELIVERED
                case DeliveryStatusEnum.CANCELED_BY_PROVIDER:
                case DeliveryStatusEnum.CANCELED_BY_CUSTOMER:
                case DeliveryStatusEnum.EXPIRED:
                case DeliveryStatusEnum.FAILED:
                    return StepsStatusEnum.CANCELLED
                default:
                    return StepsStatusEnum.PENDING;
            }
        },
        providerImage() {
            switch (this.delivery?.deliveryProvider) {
                case DeliveryProvidersEnum.ORKESTRO:
                    return 'https://ucarecdn.com/b2672608-826b-4819-a8e1-bd958df2def8/'
                case DeliveryProvidersEnum.NASH:
                  return 'https://ucarecdn.com/3595a6df-0d5c-4dbe-839f-ece596dfbb83/-/format/auto/-/resize/200x/';
                case DeliveryProvidersEnum.SHIPDAY:
                    return 'https://ucarecdn.com/f787a41a-d881-482e-9522-7aa199c2fc84/-/format/auto/-/resize/200x/';
                case DeliveryProvidersEnum.PEDAL_ME:
                    return 'https://ucarecdn.com/721c860f-411b-47f8-bd18-a6d3b06adffe/-/format/auto/-/resize/200x/';
                default:
                    return false
            }
        },
        providerDashboardLink() {
            switch (this.delivery?.deliveryProvider) {
                case DeliveryProvidersEnum.ORKESTRO:
                    return 'https://app.orkestro.com/';
                case DeliveryProvidersEnum.NASH:
                    return `https://portal.usenash.com/active/${this.delivery.deliveryProviderId}/`;
                case DeliveryProvidersEnum.SHIPDAY:
                    return 'https://dispatch.shipday.com/dashboard';
                case DeliveryProvidersEnum.PEDAL_ME:
                   return `https://pedalme.app/bookings/${this.delivery.deliveryProviderId}/edit`;
                default:
                    return false
            }

        }
    }
};
</script>

<style src="@/assets/css/components/order/delivery-management.css" scoped></style>
